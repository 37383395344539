jQuery(function($){
  var $swiper;
  var bulletClass = 'history-slider__teaser';
  var $historyHeader = $('.history-header');
  var headerHeight = $historyHeader.height();
  var isSlideChanging = false;
  var isSlidingDown = true;
  var isScrolling = false;
  var index = 0;

  $historyHeader.parent().css({position: 'relative'});

  function getHeaderHeight(isSlidingDown) {
    if($(window).width() < 1024) {
      if(isSlidingDown) {
        return 111;
      } else {
        return 135 + headerHeight;
      }
    } else {
      if(isSlidingDown) {
        return 66;
      } else {
        return 90;
      }
    }
  }


  $('.history-slider__slider').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');
    var $sliderPagination = $slider.find('.swiper-pagination');

    // Init slider
    $swiper = new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      effect: 'fade',
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
      pagination: {
        el: $sliderPagination[0],
        clickable: true,
        bulletClass: bulletClass,
        renderBullet: function (index, className) {
          var slideTitle = $slider.find('.swiper-slide').eq(index).find('.history-slider__date').text();
          return '<button class="'+bulletClass+'" data-index="'+index+'" data-target="#'+index+'">'+slideTitle+'</button>';
        },
      },
      on: {
        slideChange: function () {
          var indexActive = $('.swiper-pagination-bullet-active').data('index');
          isSlidingDown = (indexActive > index)? true: false;
          index = indexActive;

          $('.history-slider__icon').css('transform', 'rotate('+30 * indexActive+'deg)');

          if(!isSlideChanging && !isScrolling) {
            isSlideChanging = true;
            $('html, body').animate({
              scrollTop: $($('.swiper-pagination-bullet-active').data('target')).offset().top - getHeaderHeight(isSlidingDown)},
              'slow',
              function() {
                isSlideChanging = false;
              }
            );
          }
        },
      },
    });
  });

  $window = $(window);
  var position, scroll = $(window).scrollTop();
  $(window).on('scroll', function () {
    window.requestAnimationFrame(function() {

      // Detect scroll end
      isScrolling = true;
      var scrollTimeout;
      window.clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function() {
        isScrolling = false;
      }, 250);

      // Shrink header
      scroll = $(window).scrollTop();
      if (scroll >= getHeaderHeight(isSlidingDown)) {
        $historyHeader.addClass('is-fixed');
      }
      if (scroll == 0 && !isSlidingDown) {
        $historyHeader.removeClass('is-fixed');
      }
      if (scroll > position) {
        isSlidingDown = true;
        $historyHeader.addClass('is-hidden');
      }
      if (scroll < position) {
        isSlidingDown = false;
        $historyHeader.removeClass('is-hidden');
      }
      position = scroll;

      var bottom = $window.scrollTop() + $window.height();
      $historyHeader.toggleClass('is-bottom', $('footer').offset().top <= bottom);

      // Scroll Spy
      if (!isSlideChanging) {
        scrollCalc = $window.scrollTop() + ($window.height() / 2);
        var $sections = $('.history-content');
        for (var i = 0; i < $sections.length; i++) {
          var $section = $($sections.get(i));
          if ($section.offset().top + $section.outerHeight() > scrollCalc) {
            $swiper.slideTo(i);
            break;
          }
        }
      }
    });
  }).trigger('scroll');

});
