(function ($) {
  var changePe = 0;

  function getAjax(offset, type_publication, post_per_pages) {
    if (changePe === 1) {
      return;
    }

    changePe = 1;
    let btnPe = $('#btn_series');
    $.ajax({
      url: btnPe.data('ajax'),
      method: 'POST',
      data: {
        'action': 'more_series',
        'offset': offset,
        'type_publication': type_publication,
        'post_per_pages': post_per_pages,
      },
    }).done(function (response) {
      changePe = 0;
      var offset = parseInt(btnPe.data('offset'));
      offset += $(response).find('.news-listing__item').length;
      if (offset >= btnPe.data('max')) {
        btnPe.hide();
      }
      btnPe.data('offset', offset);
      $('.asterisk_series-listing__list').append(response);
    });
  }

  $(function () {
    $('#btn_series').on('click', function () {
      var offset = $(this).data('offset');
      var type_publication = $(this).data('type_publication');
      var post_per_pages = $(this).data('post_per_pages');
      getAjax(offset, type_publication, post_per_pages);
    });
  });
})(jQuery);
