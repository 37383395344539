(function ($) {
  var changePe = 0;

  function getAjax(offset, type_publication) {
    if (changePe === 1) {
      return;
    }

    changePe = 1;
    let btnPe = $('#btn_pe');
    $.ajax({
      url: btnPe.data('ajax'),
      method: "POST",
      data: {
        'action': 'more_pe',
        'offset': offset,
        'type_publication': type_publication,
      }
    }).done(function (response) {
      changePe = 0;
      var offset = parseInt(btnPe.data('offset'));
      offset += $(response).find('.news-listing__item').length;
      if (offset >= btnPe.data('max')) {
        btnPe.hide();
      }
      btnPe.data('offset', offset);
      $('.asterisk_pe-listing__list').append(response);
    });
  }

  $(function () {
    $('#btn_pe').on('click', function () {
      var offset = $(this).data('offset');
      var type_publication = $(this).data('type_publication');
      getAjax(offset, type_publication);
    });
  });
})(jQuery);
