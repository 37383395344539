jQuery(function($){
  $('.check-exploitation').on('click', function () {

    if (0 == $('.check-exploitation:checked').length) {
      $('.hide-exploitation').each(function () {
        $(this).show();
      });
    } else {
      $('.hide-exploitation').each(function () {
        $(this).hide();
      });
      $('.check-exploitation:checked').each(function () {
        var value = $(this).val();
        $('.hide-exploitation').each(function () {
          var categories = $(this).data('exploitation');
          if (categories != undefined) {
            categories = categories.toString().split(',');
            if (-1 != $.inArray(value, categories)) {
              $(this).show();
            }
          }
        });
      });
    }
  });
});
