jQuery(function($){
  if ($('.video__btn').length){
    $('.video__btn').on('click', function () {
      if (undefined != $(this).closest('.live-teaser').html()) {
        return;
      }
      var url = $(this).closest('.video').data('video');
      if (url == undefined) {
        return;
      }
      var player = document.createElement("IFRAME");
      player.setAttribute('width', '640');
      player.setAttribute('height', '360');
      player.setAttribute('frameborder', 0);
      player.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture');
      player.setAttribute('src', url);
      $(this).closest('.l-embed').html(player);
    });
  }
});
