jQuery(function($){
  $('.js-mfp-contact').magnificPopup({
    type: 'inline',
    preloader: false,
  });
  $('.contactbtn').on('click', function () {
    $('#nf-field-60').val($(this).data('id'));
    $('#nf-form-title-6>h3').html($(this).data('title'));
  });
});
