jQuery(function($){
  $('.theme-header').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');
    var $sliderPagination = $slider.find('.swiper-pagination');
    var bulletClass = 'theme-header__teaser';

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      effect: 'fade',
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
      pagination: {
        el: $sliderPagination[0],
        clickable: true,
        bulletClass: bulletClass,
        renderBullet: function (index, className) {
          var slideTitle = $slider.find('.swiper-slide').eq(index).find('.slide__title').text();
          return '<button class="'+bulletClass+'">'+slideTitle+'</button>';
        },
      },
    });

  });
});
