jQuery(function($){
  $('.zoom-images-listing').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      }
    });
  });

  $('.js-mfp-zoom-image').magnificPopup({
    type: 'image',
    preloader: false,
    // titleSrc: 'title'
  });
});
