(function ($) {
  // ---------- APP START
  var App = {
    config: {
      scrollOffset: 0,
    },

    getCookie: function (cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    GrantsSelect: function () {
      var $listContainer = $(".grants-listing__list .sv-g");
      var $list = $listContainer.find(".sv-u-1");
      $("#grants_select").on("change", function () {
        var value = $("#grants_select").val();
        $list.detach();
        $list.each(function () {
          var categories = $(this).data("creation");
          if (value == "" || undefined == categories) {
            $listContainer.append($(this));
          } else {
            categories = categories.toString().split(",");
            if (-1 != $.inArray(value, categories)) {
              $listContainer.append($(this));
            }
          }
        });
      });
    },

    tacInit: function () {
      $("a").on("click", function () {
        if ($(this).attr("href") == "#rgpd") {
          tarteaucitron.userInterface.openPanel();
        }
      });
    },

    setCookie: function (cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    b64DecodeUnicode: function (str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },

    sso: function () {
      var base64 = App.getCookie("extranet-session-data");
      if ("" == base64) {
        $("#sso").hide("slow");
        return;
      }

      var text = App.b64DecodeUnicode(base64);
      var json = JSON.parse(text);
      $("#sso_firstname").text(json.firstname);
      $("#sso_lastname").text(json.lastname);
      $(".header__btn").hide("slow");
      $("#sso").show("slow");
    },

    scripts: function () {
      App.sso();
      App.tacInit();
      App.GrantsSelect();
    },

    init: function () {
      // Init SVG polyfill
      if (typeof svg4everybody !== "undefined") svg4everybody();

      // Init object-fill polyfill
      if (typeof objectFitImages !== "undefined") objectFitImages();

      // Remove focus state when clicking on a button with a mouse
      $(":not(form) button, a").on("click", function (e) {
        if (e.clientX && e.clientY) $(this).blur();
      });

      let userAgent = navigator.userAgent;
      let browserName;
      
      if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      } else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      } else if(userAgent.match(/safari/i)){
        browserName = "safari";
      } else if(userAgent.match(/opr/i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      } else{
        browserName="No browser detection";
      }

      $('html').attr('data-navigator', browserName);

      $('a[href^="#"]')
        .not(".js-no-scroll")
        .on("click", function (e) {
          e.preventDefault();

          var scrollTo = $(this).data("scroll-to") ? $(this).data("scroll-to") : $(this).attr("href");

          if ($(scrollTo).length > 0 && $(this).data("prevent-scroll") !== true)
            App.scroll(scrollTo, $(this).data("scroll-offset"));
        });

      if ($("[data-scroll]").length) {
        App.scroll($("[data-scroll]").data("scroll"));
      }
      App.initClickableDivs();

      // Set view height var
      $(window)
        .on("resize", function () {
          var vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty("--vh", vh + "px");
        })
        .trigger("resize");

      if ($(".l-scrollbar").length) App.initScrollBar();
      if ($(".js-select").length) App.initSelect();
    },

    load: function () {
      if (window.ga === undefined) {
        window.ga = function () {};
      }
      App.scripts();
    },

    // Make div (with eventually child links) clickable
    initClickableDivs: function (context) {
      var clickableDivs;

      if (context === undefined) {
        clickableDivs = $("[data-href]");
      } else if (context.data("href")) {
        clickableDivs = context;
      } else {
        clickableDivs = context.find("[data-href]");
      }

      var enable = true;

      clickableDivs
        .click(function () {
          if (enable) {
            if ($(this).data("href-target") == "_blank") {
              window.open($(this).data("href"), "_blank");
            } else {
              document.location.href = $(this).data("href");
            }
          }
        })
        .find("a")
        .click(function (e) {
          if (enable) {
            e.stopPropagation();
          }
        });
    },

    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;

      $("html, body").animate(
        { scrollTop: $(element).offset().top - offset },
        "slow"
      );
    },

    sendGaEvent: function (eventCategory, eventAction, eventLabel) {
      if (typeof ga == "function") {
        ga("send", "event", eventCategory, eventAction, eventLabel, {
          transport: "beacon",
        });
      }
    },

    initScrollBar: function () {
      $(".l-scrollbar").each(function () {
        new PerfectScrollbar(this);
      });
    },

    initSelect: function () {
      $(".js-select").select2({
        minimumResultsForSearch: Infinity,
      });

      $(".js-select").on("select2:open", function () {
        $(this).parent().find(".select2-search__field").prop("disabled", true);
      });
    },

    ninjaForms: function () {
      $("select.ninja-forms-field").select2({
        minimumResultsForSearch: Infinity,
      });

      $(".js-newsletter-form-trigger input").on("click", function () {
        $(".footer__newsletter-form .is-hidden").removeClass("is-hidden");
      });
    },
  };
  // ---------- APP END

  // Init app
  $(App.init);
  $(window).on("load", App.load);
  $(window).on("nfFormReady", App.ninjaForms);
  window.App = App;
})(jQuery);
