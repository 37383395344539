(function($){
  var $grid = $('.events-listing .sv-g').masonry({
    columnWidth: $('.events-listing__list .sv-u-1').eq(0).outerWidth()
  });

  $('.event-image-teaser__image img').on('load', function() {
    $grid.masonry('layout');
  });

  $('.programmation_reset').on('click', function () {
    if ($('.date_programmation').length) {
      $('.date_programmation').empty();
    }
    if ($('.category_programmation').length) {
      $('.category_programmation').empty();
    }
    $('.programmation_teaser').each(function(){
        $(this).show();
    });

    $grid.masonry('layout');
  });
  $('.category_programmation, .date_programmation').on('change', function () {
    changeProgrammation()
  });

  function changeProgrammation()
  {
    var dates = $('.date_programmation').select2('val');
    if ($('.category_programmation').length) {
      var categories = $('.category_programmation').select2('val');
    } else {
      var categories = [];
    }
    if (dates.length == 0 && categories.length == 0) {
    $('.programmation_teaser').each(function(){
        $(this).show();
      });
    } else {
      $('.programmation_teaser').each(function(){
        $(this).hide();
      });
      $('.programmation_teaser').each(
        function(){
          if ($.inArray($(this).data('date'), dates) != -1) {
            $(this).show();
          }
        }
      );
      $(categories).each(
        function (index) {
          $('.programmation_teaser').each(function () {
            var dataCategory = $(this).data('categories');
            if (dataCategory != undefined) {
              dataCategory = dataCategory.toString().split(',');
              if (-1 != $.inArray(categories[index], dataCategory)) {
                $(this).show();
              }
            }
          });
        }
      )
    }

    $grid.masonry('layout');

  }


})(jQuery);
