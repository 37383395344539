jQuery(function($){
  $('.images-slider').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      },
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
    });

  });
});
