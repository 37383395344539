jQuery(function($){
  $('.hero-slider').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');
    var $sliderPagination = $slider.find('.swiper-pagination');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
      pagination: {
        el: $sliderPagination[0],
        clickable: true,
      },
    });
  });
});
