jQuery(function($){
  $('.thumbs-slider').each(function(){
    var $slider = $(this).find('.thumbs-slider__bottom');
    var $sliderContainer = $slider.find('.swiper-container');

    var galleryThumbs = new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchSlidesProgress: true,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      },
    });

    var $slider = $(this).find('.thumbs-slider__top');
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      effect: 'fade',
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
      thumbs: {
        swiper: galleryThumbs
      },
    });

  });
});
