jQuery(function($){
  var $grid = $('.advices-listing__list .sv-g').masonry({
    columnWidth: $('.advices-listing__list .sv-u-1').eq(0).outerWidth()
  });

  $('.filtersachez').on('click', function () {

    if (0 == $('.filtersachez:checked').length) {
      $('.sachezlebox').each(function () {
        $(this).show();
      });
    } else {
      $('.sachezlebox').each(function () {
        $(this).hide();
      });
      $('.filtersachez:checked').each(function () {
        var value = $(this).val();
        $('.sachezlebox').each(function () {
          var categories = $(this).data('categories');
          if (categories != undefined) {
            categories = categories.toString().split(',');
            if (-1 != $.inArray(value, categories)) {
              $(this).show();
            }
          }
        });
      });
    }

    $grid.masonry('layout');
  });
});
