jQuery(function($){
  window.dataLayer = window.dataLayer || [];
  window._mtm = window._mtm || [];
  $('.accordion-item__title').on('click', function(){
    var $accordion = $(this).parent();
    if ($accordion.hasClass('is-opened')){
      $accordion.removeClass('is-opened');
    } else {
      $('.accordion-item').removeClass('is-opened');
      $accordion.addClass('is-opened');
    }
  });
});
