(function ($) {
  var changeNews = 0;
  function getAjax() {
    if (changeNews == 1) {
      return;
    }

    changeNews = 1;
    $.ajax({
      url: window.ajaxurl,
      type: "GET",
      data: {
        'action': 'get_news',
        'offset': parseInt($('#btn_news').data('offset')),
        'archive': $('.news-listing-header__filters').data('archive'),
        'category': $('#select-category').val(),
        'domain': $('#select-domain').val(),
        'type': $('#select-type').val(),
        'year': $('.news-listing-header__filters').data('year')
      }
    }).done(function (response) {
      json = $.parseJSON(response);
      var data = json.data;
      changeNews = 0;
      var offset = parseInt($('#btn_news').data('offset'));
      if (offset == 0) {
        $('#btn_news').data('max', json.total);
      }

      offset += json.count;
      if (offset >= $('#btn_news').data('max')) {
        $('#btn_news').hide();
      } else {
        $('#btn_news').show();
      }
      $('#btn_news').data('offset', offset);
      $('.news-listing__list').append(data);
    });
  }
  function regenerateNews()
  {
    $('#btn_news').data('offset', 0);
    $('.news-listing__list').html('');
    getAjax();
  }
  $(function () {
    $('.news-listing-header__reset').on('click', function () {
      $("#select-category, #select-domain, #select-type").val('').trigger('change');
    });
    $("#select-category, #select-domain, #select-type").on('change', function () {
      regenerateNews();
    });
    $('#btn_news').on('click', function () {
      getAjax();
    });
    if ($('.news-listing__list').length && ($('#select-category').val().length || $('#select-domain').val().length || $('#select-type').val().length)) {
      regenerateNews();
    }
  });
})(jQuery);
