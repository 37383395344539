jQuery(function($){
  $('.series-slider').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      },
    });

  });
});
