jQuery(function($){
  window.dataLayer = window.dataLayer || [];
  window._mtm = window._mtm || [];
  $('.faq-item__question').on('click', function(){
    var $faq = $(this).parent();
    if ($faq.hasClass('is-opened')){
      $faq.removeClass('is-opened');
    } else {
      $('.faq-item').removeClass('is-opened');
      dataLayer.push({
        'event': 'clic-FAQ',
        'action': 'clic-question-FAQ',
        'name': $faq.find('.faq-item__question').text()
      });

      _mtm.push({
        'event': 'clic-FAQ',
        'action': 'clic-question-FAQ',
        'name': $faq.find('.faq-item__question').text()
      });
      $faq.addClass('is-opened');
    }
  });
  $('.checkboxfaq').on('click', function () {
    if (0 == $('.checkboxfaq:checked').length) {
      $('.faq__item').each(function () {
        $(this).show();
      });
    } else {
      $('.faq__item').each(function () {
        $(this).hide();
      });
      $('.checkboxfaq:checked').each(function () {
        var value = $(this).val();
        $('.faq__item').each(function () {
          var categories = $(this).data('categories');
          if (categories != undefined) {
            categories = categories.toString().split(',');
            if (-1 != $.inArray(value, categories)) {
              $(this).show();
            }
          }
        });
      });
    }
  });
});
