jQuery(function ($) {
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  var Domains = {
    urls: {
      creation: {
      },
      exploitation: {
      }
    },
    initUrls: function () {
      $("#changeexploitation>option").each(function () {
        var value = $(this).attr('value');
        var url = $(this).attr('data-url');
        Domains.urls.exploitation[value] = url;
        if (url == window.location.pathname) {
          setCookie('exploitation', value, 364);
          setCookie('creation', '', -1);
        }
      });
      $("#changecreation>option").each(function () {
        var value = $(this).attr('value');
        var url = $(this).attr('data-url');
          Domains.urls.creation[value] = url;
        if (url == window.location.pathname) {
          setCookie('creation', value, 364);
          setCookie('exploitation', '', -1);
        }
      });
    },
    init: function () {

      Domains.bodyDisable();

      Domains.initUrls();
      Domains.changeDomains();


      var creationId = getCookie('creation');
      Domains.hideCreation(creationId);
      Domains.checkCreation(creationId);
      Domains.bodyCreation(creationId);
      Domains.itemCreation(creationId);
      Domains.urlCreation(creationId);
      Domains.breadcrumbCreation(creationId);

      var exploitationId = getCookie('exploitation');
      Domains.hideExploitation(exploitationId);
      Domains.checkExploitation(exploitationId);
      Domains.bodyExploitation(exploitationId);
      Domains.itemExploitation(exploitationId);
      Domains.urlExploitation(exploitationId);
      Domains.breadcrumbExploitation(exploitationId);
    },
    bodyDisable: function ()
    {
      if (0 == $("#changecreation").length) {
        setCookie('creation', '', -1);
      }
      if (0 == $("#changeexploitation").length) {
        setCookie('exploitation', '', -1);
      }
    },
    addSpan: function (oldspan, element) {
      var span = document.createElement('span');
      var a = document.createElement('a');
      $(a).attr('href', $(this).attr('href'));
      $(a).html($(this).html());
      $(span).append(a);
      $(oldspan).append(span);
      return span;
    },
    changeBreadcrumb: function (change, html, url)
    {
      if (change == 0) {
        return;
      }
      var links = $('.breadcrumb a');
      var last = $('.breadcrumb_last');
      var oldspan = document.createElement('span');
      firstspan = oldspan;
      $($(links).get()).each(function (element) {
        var span = document.createElement('span');
        var a = document.createElement('a');
        $(a).attr('href', $(this).attr('href'));
        $(a).html($(this).html());
        $(span).append(a);
        $(oldspan).append(span);
        oldspan = span;
      });

      var span = document.createElement('span');
      var a = document.createElement('a');
      $(a).attr('href', url);
      $(a).html(html);
      $(span).append(a);
      $(oldspan).append(span);
      oldspan = span;

      var spanLast = document.createElement('span');
      $(spanLast).attr('class', 'breadcrumb_last');
      $(spanLast).attr('aria-current', 'page');
      $(spanLast).html($(last).html());
      $(oldspan).append(spanLast);
      $('.breadcrumb').html('').append(firstspan);
    },
    breadcrumbExploitation: function (exploitationId)
    {
      if (exploitationId == '') {
        return;
      }
      var url = '';
      var change = 1;
      $.each(Domains.urls.exploitation, function (key, path) {
        if (path == window.location.pathname) {
          change = 0;
        }
        if (key == exploitationId) {
          url = path;
        }
      });
      var data = $("#changeexploitation").select2('data');
      Domains.changeBreadcrumb(change, data[0].text, url);
    },
    breadcrumbCreation: function (creationId)
    {
      if (creationId == '') {
        return;
      }
      var url = '';
      var change = 1;
      $.each(Domains.urls.creation, function (key, path) {
        if (path == window.location.pathname) {
          change = 0;
        }
        if (key == creationId) {
          url = path;
        }
      });
      var data = $("#changecreation").select2('data');
      Domains.changeBreadcrumb(change, data[0].text, url);
    },
    urlCreation: function (creationId)
    {
      if (creationId == '' && $("#changecreation").length == 0) {
        return;
      }
      var change = 1;
      $.each(Domains.urls.creation, function (key, path) {
        if (path == window.location.pathname) {
          change = 0;
        }
      });
      if (1 == change) {
        $.each(Domains.urls.creation, function (key, path) {
          if (key == creationId) {
            $(".header__logo-baseline").attr('href', path);
          }
        });
      }
    },
    urlExploitation: function (exploitationId)
    {
      if (exploitationId == '' && $("#changeexploitation").length == 0) {
        return;
      }
      var change = 1;
      $.each(Domains.urls.exploitation, function (key, path) {
        if (path == window.location.pathname) {
          change = 0;
        }
      });
      if (1 == change) {
        $.each(Domains.urls.exploitation, function (key, path) {
          if (key == exploitationId) {
            $(".header__logo-baseline").attr('href', path);
          }
        });
      }
    },
    hideExploitation: function (exploitationId) {
      if (exploitationId == "" || 0 == $(".hide-exploitation").length) {
        return;
      }

      $('.hide-exploitation').each(function () {
        var exploitations = $(this).data('exploitation');
        if (exploitations != undefined) {
          exploitations = exploitations.toString().split(',');
          if (-1 == $.inArray(exploitationId, exploitations)) {
            $(this).hide();
          } else {
            $(this).show();
          }
        }
      });
    },
    hideCreation: function (creationId) {
      if (creationId == "" || 0 == $(".hide-creation").length) {
        return;
      }

      $('.hide-creation').each(function () {
        var creations = $(this).data('creation');
        if (creations != undefined) {
          creations = creations.toString().split(',');
          if (-1 == $.inArray(creationId, creations)) {
            $(this).hide();
          } else {
            $(this).show();
          }
        }
      });
    },
    checkCreation: function (creationId) {
      if (creationId == "" || 0 == $(".check-creation").length) {
        return;
      }
      $(".check-creation").each(function () {
        $(this).attr('checked', $(this).val() == creationId);
      });
    },
    checkExploitation: function (exploitationId) {
      if (exploitationId == "" || 0 == $(".check-exploitation").length) {
        return;
      }
      $(".check-exploitation").each(function () {
        $(this).attr('checked', $(this).val() == exploitationId);
      });
    },
    changeDomains: function () {
      if ($('#changecreation, #changeexploitation').length == 0) {
        return;
      }
      var urlSelect = ($('#changecreation').length != 0) ? Domains.urls.creation : Domains.urls.exploitation;
      var newcookie = ($('#changecreation').length != 0) ? 'creation' : 'exploitation';
      var oldcookie = ($('#changecreation').length != 0) ? 'exploitation' : 'creation';
      $.each(urlSelect, function (key, path) {
        if (path == window.location.pathname) {
          setCookie(newcookie, key, 364);
          setCookie(oldcookie, '', -1);
        }
      });

      $('#changecreation, #changeexploitation').on('change', function () {
        if ($(this).data('set') != undefined) {
          $(this).removeData('set');
        } else {
          var newcookie = ('changecreation' == $(this).attr('id')) ? 'creation' : 'exploitation';
          var urlSelect = ('changecreation' == $(this).attr('id')) ? Domains.urls.creation : Domains.urls.exploitation;
          var oldcookie = ('creation' == newcookie) ? 'exploitation' : 'creation';
          setCookie(newcookie, $(this).val(), 364);
          setCookie(oldcookie, '', -1);
          var urlOLD = window.location.pathname;
          var urlNEW = '';
          var idSelect = $(this).val();
          var find = 0;
          $.each(urlSelect, function (key, path) {
            if (path == window.location.pathname && find == 0) {
              find = 1;
            }
          });
          if (find == 1) {
            $.each(urlSelect, function (key, path) {
              if (key == idSelect) {
                urlNEW = path;
              }
            });
          } else {
            urlNEW = urlOLD;
          }
          window.location = urlNEW;
        }
      });
    },
    bodyExploitation: function (exploitationId) {
      if (0 == $("#changeexploitation").length || exploitationId == "") {
        return;
      }
      $("#changeexploitation").data('set', 'init').val(exploitationId).trigger('change');
    },
    bodyCreation: function (creationId) {
      if (0 == $("#changecreation").length || creationId == "") {
        return;
      }
      var themes = {
        89: 'm--theme-audiovisual',
        90: 'm--theme-writing',
        91: 'm--theme-sound',
        92: 'm--theme-audiovisual',
        93: 'm--theme-image',
        94: 'm--theme-audiovisual',
        95: 'm--theme-sound',
        96: 'm--theme-journalism',
        97: 'm--theme-audiovisual',
        98: 'm--theme-image',
        99: 'm--theme-emerging-shape',
      };
      $("#changecreation").data('set', 'init').val(creationId).trigger('change');
      $.each(themes, function (key, value) {
        $(".body-creation").removeClass(value);
      });
      if (themes[creationId] != undefined) {
        $(".body-creation").addClass(themes[creationId]);
      }
    },
    itemCreation: function (creationId) {
      if ($(".item-creation").length == 0 || creationId == '') {
        return;
      }
      $('.item-creation').each(function () {
        var creations = $(this).data('creation');
        if (creations != undefined) {
          creations = creations.toString().split(',');
          if (-1 == $.inArray(creationId, creations)) {
            $(this).remove();
          }
        }
      });
    },
    itemExploitation: function (exploitationId) {
      if ($(".item-exploitation").length == 0 || exploitationId == '') {
        return;
      }
      $('.item-exploitation').each(function () {
        var exploitations = $(this).data('exploitation');
        if (exploitations != undefined) {
          exploitations = exploitations.toString().split(',');
          if (-1 == $.inArray(exploitationId, exploitations)) {
            $(this).remove();
          }
        }
      });
    }
  };
  Domains.init();
});
