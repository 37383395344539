(function ($) {
  var changeNews = 0;
  function getAjaxMovie(offset) {
    if (changeNews == 1) {
      return;
    }

    changeNews = 1;
    $.ajax({
      url: window.ajaxurl,
      type: "GET",
      data: {
        'action': 'get_movie',
        'offset': offset,
      }
    }).done(function (response) {
      changeNews = 0;
      var offset = parseInt($('#btn_movie').data('offset'));
      offset += $(response).find('.movies-listing__item').length;
      if (offset >= $('#btn_movies').data('max')) {
        $('#btn_movies').hide();
      }
      $('#btn_movies').data('offset', offset);
      $('.movies-listing__list').append(response);
    });
  }
  $(function () {
    $('#btn_movies').on('click', function () {
      var offset = $(this).data('offset');
      getAjaxMovie(offset);
    });
  });
})(jQuery);
