jQuery(function($) {
  window.dataLayer = window.dataLayer || [];
  window._mtm = window._mtm || [];

  $(document).on('nfFormSubmitResponse', function(event, response, id) {
    if (response.response.errors.length) return;

    dataLayer.push({
      'event': 'form-submission',
      'form': {
        'id': response.id,
        'values': response.response.data.fields_by_key,
      },
    });

    _mtm.push({
      'event': 'form-submission',
      'form': {
        'id': response.id,
        'values': response.response.data.fields_by_key,
      },
    });
  });

  $('a.contact').on('click', function(event) {
    _mtm.push({
      'event': 'contact-click',
      'href': this.href,
    });
  });

  $('a.rs__link').on('click', function(event) {
    _mtm.push({
      'event': 'news-share',
      'href': this.href,
      'network': $(this).find('span').text(),
    });
  });
});
