jQuery(function($){
  $('.top-news-listing').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      breakpoints: {
        640: {
          slidesPerView: 2,
        }
      },
      on: {
        init: function () {
          if(window.innerWidth >= 1024) {
            this.disable();
          }
        },
        resize: function () {
          if(window.innerWidth >= 1024) {
            this.disable();
          } else {
            this.enable();
          }
        }
      }
    });
  });
});
