(function ($) {
  function getAjaxSearch(offset) {
    $.ajax({
      url: '/',
      type: "GET",
      data: {
        's': $('#btn_search').data('search'),
        'offset': offset,
      }
    }).done(function (response) {
      var more_html = $(response).find('.search-results-listing__list').html();
      var $button = $(response).find('#btn_search');
      if ($button.length) {
        $('#btn_search').data('offset', $button.data('offset'));
      }
      else {
        $('#btn_search').hide();
      }
      $('.search-results-listing__list').append(more_html);
    }).always(function () {
      $('#btn_search').prop('disabled', false);
    });
  }
  $(function () {
    $('#btn_search').on('click', function () {
      var offset = $(this).data('offset');
      $(this).prop('disabled', true);
      getAjaxSearch(offset);
    });
  });
})(jQuery);
