jQuery(function($){
  $('.ceos-slider').each(function(){
    var $slider = $(this);
    var $sliderContainer = $slider.find('.swiper-container');
    var $sliderArrowPrev = $slider.find('.slider__arrow-prev');
    var $sliderArrowNext = $slider.find('.slider__arrow-next');
    var $sliderScrollbar = $slider.find('.slider__scrollbar');

    // Init slider
    new Swiper($sliderContainer[0], {
      slidesPerView: 1,
      watchOverflow: true,
      breakpoints: {
        1366: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      },
      navigation: {
        prevEl: $sliderArrowPrev[0],
        nextEl: $sliderArrowNext[0],
      },
      scrollbar: {
        el: $sliderScrollbar[0],
        draggable: true,
      }
    });

  });

  $('.ceo').on('click', function() {
    $(this).toggleClass('is-opened');
  });
});
