jQuery(function($){
  $('.js-mfp-filmmaker').magnificPopup({
    type: 'inline',
    preloader: false,
    gallery: {
      enabled: true
    },
    callbacks: {
      open: function() {
        $('.filmmaker-popup').append($('.mfp-arrow'));
      },
      close: function() {
        $('.filmmaker-popup .mfp-arrow').remove();
      }
    }
  });
});
