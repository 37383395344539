jQuery(function($){
  $('.js-mfp-live').magnificPopup({
    type: 'iframe',
    preloader: false,
    iframe: {
      markup: $('.live__popup').html(),
      patterns: {
        youtube: {
          id: function(url){
            return url;
          },
          src: '%id%'
        },
        vimeo: {
          id: function(url){
            return url;
          },
          src: '%id%'
        }
      }
    }
  });

  $('.js-close-teaser').on('click', function(e) {
    e.preventDefault();
    $('.live-teaser').addClass('is-semantic');
  });
});
