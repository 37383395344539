jQuery(function($){
  var $header = $('.header');

  // Shrink header
  var headerHeight = $header.height();
  var homeHeaderHeight = $('.home-header').innerHeight();
  var scroll;
  $(window).on('scroll', function () {
    window.requestAnimationFrame(function() {
      scroll = $(window).scrollTop();
      $header.toggleClass('is-shrinked-home', (scroll >= homeHeaderHeight));
      $header.toggleClass('is-shrinked', (scroll >= headerHeight));
    });
  });

  // Toggle contents
  $('.js-toggle').on('click', function() {
    var that = this;

    // Close other contents
    $('.js-toggle.is-opened').each(function() {
      if (that != this) {
        $(this).removeClass('is-opened');
        $($(this).data('target')).removeClass('is-opened');
      }
    });

    // Toggle targeted content
    $(this).toggleClass('is-opened');
    $($(this).data('target')).toggleClass('is-opened').trigger('classChange');
  });

  // Toggle languages
  $('.js-toggle-languages').on('click', function() {
    $(this).toggleClass('is-opened');
  });

  $('#header-search-bar').on('classChange', function () {
    if ($(this).hasClass('is-opened')) {
      $('.search-bar__input').trigger('focus');
    }
  });

  // Menu navigation
  $('.header .menu-item-has-children > a').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('is-opened');
  });
});
